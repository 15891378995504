.btn-skin-animate {
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes btn-skin-rotate {
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}