.orbit-2{
    height: 50vw;
    width: 50vw;
    position: absolute;
    left: -10vw;
    top: -20%;
    animation-name: orbit-2-animate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes orbit-2-animate {
    0%{
        transform: rotate(0deg);
    }

    50%{
        transform: rotate(-180deg);
    }

    100%{
        transform: rotate(-360deg);
    }
}

.earth-orb {
    position: absolute;
    top: 94%;
    left: 30%;
    animation-name: earth-orb-animate;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes earth-orb-animate {
    0%{
        transform: rotate(0deg);
    }

    50%{
        transform: rotate(180deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

.earth {
    height: 5vw;
    width: 5vw;
}

.earth-sattelite-0 {
    height: 2vw;
    width: 2vw;
    position: absolute;
    top: -20%;
    left: -20%;
    animation-name: sattelite-0;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes sattelite-0 {
    0%{
        top: 50%;
        left: -45%;
        scale: 1;
    }

    25%{
        scale: 1.5;
    }

    50%{
        top: 50%;
        left: 115%;
        scale: 1;
    }

    75%{
        scale: 0.5;
    }

    100%{
        top: 50%;
        left: -45%;
        scale: 1;
    }
}

.earth-sattelite-1 {
    height: 3vw;
    width: 3vw;
    position: absolute;
    top: -20%;
    left: -20%;
    animation-name: sattelite-1;
    animation-duration: 16s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes sattelite-1 {
    0%{
        top: 110%;
        left: -40%;
        scale: 1;
    }

    25%{
        scale: 1.2;
    }

    50%{
        top: -40%;
        left: 80%;
        scale: 1;
    }

    75%{
        scale: 0.5;
    }

    100%{
        top: 110%;
        left: -40%;
    }
}

.deamos{
    height: 4.6vw;
    width: 4.6vw;
    position: absolute;
    top: 65%;
    left: 0%;
}

.fobos{
    height: 6vw;
    width: 6vw;
    position: absolute;
    top: 80%;
    left: 80%;
}