.invisible {
    opacity: 0.05;
}

.circle {
    border-radius: 100%;
}

.filled-light {
    background-color: #FFF8F3;
}

.orbit-light {
    border: 1px solid #FFF8F3;
}

.filled-dark {
    background-color: #0B0A0E;
}

.orbit-dark {
    border: 1px solid #0B0A0E;
}

.star {
    height: 20vw;
    width: 20vw;
    position: absolute;
    left: 10vw;
    top: 0;
    transform: translateY(-50%);
}