.orbit-1{
    height: 40vw;
    width: 40vw;
    position: absolute;
    left: -2vw;
    top: 0;
    transform: translateY(-30%);
    animation-name: orbit-1-animate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes orbit-1-animate {
    0%{
        transform: translateY(-30%) rotate(0deg);
    }

    50%{
        transform: translateY(-30%) rotate(-180deg);
    }

    100%{
        transform: translateY(-30%) rotate(-360deg);
    }
}

.venus-orb {
    position: absolute;
    top: 90%;
    left: 50%;
    animation-name: venus-orb-animate;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes venus-orb-animate {
    0%{
        transform: rotate(0deg);
    }

    50%{
        transform: rotate(180deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

.venus {
    height: 5vw;
    width: 5vw;
}

.venus-sattelite {
    height: 2vw;
    width: 2vw;
    position: absolute;
    top: -10%;
    left: -30%;
    animation-name: sattelite;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes sattelite {
    0%{
        top: -15%;
        left: -35%;
        scale: 1;
    }

    25%{
        scale: 1.5;
    }

    50%{
        top: 90%;
        left: 90%;
        scale: 1;
    }

    75%{
        scale: 0.25;
    }

    100%{
        top: -15%;
        left: -35%;
        scale: 1;
    }
}

.chronus {
    height: 5vw;
    width: 5vw;
    position: absolute;
    top: 10%;
    left: 5%;
}