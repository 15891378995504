.main {
    height: 100vh;
    width: 100%;
    background: linear-gradient(135deg, #1E1B29 0%, #000000 50%, #080028 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.main .system-wrap {
    position: absolute;
    top: -2vh;
    left: 0;
}

.title {
    color: #CDC8EC;
    display: inline-block;
    user-select: none;
    animation: text-shine;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

.title-gradient {
    color: transparent;
    background: linear-gradient(90deg, #FFCDB8 0%, #CDC8EC 17%);
    background-clip: text;
    -webkit-background-clip: text;
}

.title::selection {
    color: transparent;
    background: linear-gradient(90deg, #FFCDB8 0%, #CDC8EC 17%);
}

@keyframes text-shine {
    0% {
        background: transparent;
        color: transparent
    }

    100% {
        -webkit-background-clip: text;
        color: #CDC8EC
    }
}
