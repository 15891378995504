.arrow {
    animation-name: arrowAnimate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


@keyframes arrowAnimate {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.1);
    }

    20% {
        transform: scale(0.9);
    }

    60% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}