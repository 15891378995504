.orbit-0 {
    height: 26vw;
    width: 26vw;
    position: absolute;
    left: 5vw;
    top: 0;
    transform: translateY(-45%);
    animation-name: orbit-0-animate;
    animation-duration: 90s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.mercury {
    height: 5vw;
    width: 5vw;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
}

@keyframes orbit-0-animate {
    0%{
        transform: translateY(-45%) rotate(0deg);
    }

    50%{
        transform: translateY(-45%) rotate(-180deg);
    }

    100%{
        transform: translateY(-45%) rotate(-360deg);
    }
}