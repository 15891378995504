@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;&display=swap');

body {
  margin: 0;
  font-family: "M PLUS 1", "Montserrat", "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFDFC;
  color: #0B0A0E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
